<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/groups/${$route.params.uuid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-card>
      <h4>
          {{$t("Labels.GroupInformation")}}
      </h4>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="groupValidation">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Name')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="group.name"
                :state="errors.length > 0 ? false:null"
                name="name"
                :placeholder="$t('Labels.Name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.Description')"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-input
                id="description"
                v-model="group.description"
                :state="errors.length > 0 ? false:null"
                name="description"
                :placeholder="$t('Labels.Description')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      </validation-observer>
      <b-row>
        <b-col cols="12" md="6" offset-md="6" class="d-flex justify-content-end">
          <b-col cols="6">
            <b-button type="submit" block variant="primary" @click="validationForm" class="mt-3">{{$t("Labels.Save")}}</b-button>
          </b-col>
          &nbsp;
          <b-col cols="6">
            <b-button type="reset" block variant="danger" @click="cancel()" class="mt-3" >{{$t("Labels.Cancel")}}</b-button>
          </b-col>
        </b-col>
      </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'

const DATA = {
  code: 200,
  data: {
    name: 'Familia',
    description: 'mi familia'
  },
  msg: 'Ok'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      group: {},
      email: '',
      members: [],
      required,
      DATA,
      avatar: '',
      file: '',
      uuid: this.$route.params.uuid
    }
  },
  methods: {
    cancel () {
      this.group.name = ''
      this.group.description = ''
      this.$router.push(`/groups/${this.uuid}`)
    },
    async add () {
      const params = {
        url: 'tracker_groups/create',
        method: 'POST',
        params: {
          name: this.group.name,
          description: this.group.description,
          tracker_uuid: this.uuid
        }
      }
      await request(params).then(response => {
        this.$router.push(`/groups/${this.uuid}`)
      })
    },
    validationForm () {
      this.$refs.groupValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    }
  }
}
</script>
